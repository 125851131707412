import React from "react";
import { Profiler } from "./components/Profiler";

const AppRoutes = [
  {
    index: true,
    element: <Profiler />,
  },
  {
    path: "profile",
    element: <Profiler />,
  },
];

export default AppRoutes;
