import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import useAutosizeTextArea from "../hooks/useAutosizeTextArea";
import debounce from "lodash.debounce";
import { CsvDownloadIcon } from "./DownloadIcon";
import { ResultsTable } from "./ResultsTable";
import { ResultsParagraph } from "./ResultsParagraph";
import { LevelsStackedBarChart } from "./LevelsStackedBarChart";

type ResultRow = {
  occurrences: number;
  rowHtml: string;
};

type ResultColumn = {
  percentage: string;
  rows: ResultRow[];
};

export type TableResult = {
  A1: ResultColumn;
  A2: ResultColumn;
  B1: ResultColumn;
  B2: ResultColumn;
  C1: ResultColumn;
  C2: ResultColumn;
  "Off-List": ResultColumn;
};

export type ProfileResult = {
  totalWordCount: number;
  paragraphHtml: string;
  tableResult: TableResult;
};

export const Profiler = () => {
  const [showTable, setShowTable] = useState(false);
  const [profile, setProfile] = useState<ProfileResult>(defaultResponse);

  const [shouldProfileOnChange, setShouldProfileOnChange] =
    useState<boolean>(true);
  const [value, setValue] = useState("The book is on the table.");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleSubmit, 800), []);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
    if (shouldProfileOnChange) {
      debounceFn(val);
    }
  };

  function handleSubmit(value: string) {
    const fetchProfile = async () => {
      const response = await fetch("profiler", {
        method: "POST",
        headers: {
          Accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProfilerType: "cefr",
          InputText: value ?? "The book is on the table.",
        }),
      });
      return await response.json();
    };
    fetchProfile().then((profile) => {
      setProfile(profile);
    });
  }

  return (
    <>
      <Col sm="12" md="6">
        <LevelsStackedBarChart profile={profile} />
        <Row
          className="d-flex justify-content-between"
          style={{
            marginTop: 8,
            marginBottom: 5,
          }}
        >
          <Col xs="12" sm="6">
            <FormGroup check>
              <Input
                type="checkbox"
                id="shouldProfileOnChange"
                name="shouldProfileOnChange"
                defaultChecked={true}
                onChange={() => setShouldProfileOnChange((prev) => !prev)}
              />
              <Label check for="shouldProfileOnChange">
                Profile as you type
              </Label>
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" className="d-flex flex-row-reverse">
            <Button
              color="primary"
              size="sm"
              onClick={() => handleSubmit(value)}
            >
              Submit
            </Button>

            <ButtonGroup style={{ marginRight: 8 }}>
              <Button
                color="primary"
                outline
                size="sm"
                onClick={() => setShowTable(false)}
                active={!showTable}
              >
                Text
              </Button>
              <Button
                color="primary"
                size="sm"
                outline
                onClick={() => setShowTable(true)}
                active={showTable}
              >
                Table
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <textarea
          id="profiler-text"
          onChange={handleChange}
          placeholder="Copy and paste some text here to check its vocabulary level."
          ref={textAreaRef}
          value={value}
          rows={10}
          style={{ width: "100%" }}
        />
        <Row>
          <Col xs="12" className="d-flex flex-row-reverse">
            <Button
              color="primary"
              size="sm"
              onClick={() => handleSubmit(value)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
      <Col sm="12" md="6">
        {profile?.tableResult && showTable && (
          <ResultsTable profile={profile} value={value} />
        )}

        {profile?.paragraphHtml && !showTable && (
          <ResultsParagraph profile={profile} value={value} />
        )}
      </Col>
    </>
  );
};

const defaultResponse = {
  totalWordCount: 6,
  paragraphHtml:
    "<span class='profilerA1Word'>The</span> <span class='profilerA1Word'>book</span> <span class='profilerA1Word'>is</span> <span class='profilerA1Word'>on</span> <span class='profilerA1Word'>the</span> <span class='profilerA1Word'>table</span>.",
  tableResult: {
    A1: {
      percentage: "100%",
      rows: [
        {
          occurrences: 1,
          rowHtml: "<span class='word profilerA1Word'>book</span>",
        },
        {
          occurrences: 1,
          rowHtml: "<span class='word profilerA1Word'>is</span>",
        },
        {
          occurrences: 1,
          rowHtml: "<span class='word profilerA1Word'>on</span>",
        },
        {
          occurrences: 1,
          rowHtml: "<span class='word profilerA1Word'>table</span>",
        },
        {
          occurrences: 2,
          rowHtml: "<span class='word profilerA1Word'>the</span>",
        },
      ],
    },
    A2: {
      percentage: "0%",
      rows: [],
    },
    B1: {
      percentage: "0%",
      rows: [],
    },
    B2: {
      percentage: "0%",
      rows: [],
    },
    C1: {
      percentage: "0%",
      rows: [],
    },
    C2: {
      percentage: "0%",
      rows: [],
    },
    "Off-List": {
      percentage: "0%",
      rows: [],
    },
  },
};
