import React, { useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { ProfileResult } from "./Profiler";
import { WordDocDownloadIcon } from "./DownloadIcon";

const ToggleButton = ({
  label,
  profilerClass,
  onToggle,
}: {
  label: string;
  profilerClass: string;
  onToggle: (profilerClass: string) => void;
}) => {
  const [isToggled, setIsToggled] = useState(false);

  const baseStyle = {
    ...(isToggled && {
      border: "none",
      textDecoration: "line-through",
      fontWeight: "unset",
    }),
    background: "#d3d3d3",
    minWidth: 45,
    padding: 2,
    marginRight: 8,
    ...(!isToggled && { border: "1px solid black", fontWeight: "bold" }),
  };

  return (
    <Button
      type="button"
      className={profilerClass}
      style={baseStyle}
      onClick={() => {
        setIsToggled(!isToggled);
        onToggle(profilerClass);
      }}
    >
      {label}
    </Button>
  );
};

export const ResultsParagraph = ({
  profile,
  value,
}: {
  profile: ProfileResult;
  value: string;
}) => {
  const cefrLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];
  const profileParagraphRef = useRef<HTMLParagraphElement | null>(null);

  function toggleProfilerColor(classToToggle: string) {
    const offList = "profilerOffList";
    const matches =
      profileParagraphRef.current?.getElementsByClassName(classToToggle);
    if (matches) {
      for (let item of matches) {
        if (item.classList.contains(offList)) {
          item.classList.remove("profilerOffList");
        } else {
          item.classList.add("profilerOffList");
        }
      }
    }
  }

  return (
    <>
      <Row className="d-flex justify-content-between" style={{ marginTop: 8 }}>
        <Col xs="12">
          <WordDocDownloadIcon value={value} />
          {cefrLevels.map((l) => {
            const profilerClass = `profiler${l}Word`;
            return (
              <ToggleButton
                key={l}
                label={l}
                profilerClass={profilerClass}
                onToggle={() => toggleProfilerColor(profilerClass)}
              />
            );
          })}{" "}
          <p style={{ fontSize: 12 }}>Toggle level visibility</p>
        </Col>
      </Row>
      <div id="resultsParagraph">
        <p
          dangerouslySetInnerHTML={{ __html: profile.paragraphHtml ?? "" }}
          ref={profileParagraphRef}
          style={{ width: "100%", marginTop: 10 }}
        ></p>
      </div>
    </>
  );
};
