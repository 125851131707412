import React from "react";
import {
  StackedBarChart,
  IChartDataPoint,
  IChartProps,
} from "@fluentui/react-charting";
import { ProfileResult } from "./Profiler";

const cefrColors = [
  { level: "A1", hex: "#0099CC" },
  { level: "A2", hex: "#00bb00" },
  { level: "B1", hex: "#ff9900" },
  { level: "B2", hex: "#B30000" },
  { level: "C1", hex: "#D733FF" },
  { level: "C2", hex: "#DB7093" },
  { level: "Off-List", hex: "#000" },
];

export const LevelsStackedBarChart = ({
  profile,
}: {
  profile: ProfileResult;
}) => {
  if (!profile.tableResult) return null;

  const points: IChartDataPoint[] = Object.entries(profile.tableResult).map(
    ([level, levelData]) => {
      return {
        legend: `${level}: ${levelData.percentage}`,
        data: Number(levelData.percentage.replace("%", "")),
        color: cefrColors.find((c) => c.level === level)?.hex,
      };
    }
  );
  const chartTitle = `CEFR Levels of ${profile.totalWordCount} Words`;

  const data: IChartProps = {
    chartTitle: chartTitle,
    chartData: points,
  };

  return <StackedBarChart data={data} enabledLegendsWrapLines={true} />;
};
