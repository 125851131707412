import React, { useState } from "react";
import { Button, TabContent, TabPane, Table } from "reactstrap";
import { ProfileResult } from "./Profiler";
import { CsvDownloadIcon } from "./DownloadIcon";

const ToggleButton = ({
  label,
  profilerClass,
  isOffList,
  isActive,
  onClick,
}: {
  label: string;
  profilerClass: string;
  isOffList: boolean;
  isActive: boolean;
  onClick: () => void;
}) => {
  const baseStyle = {
    ...(isActive && { border: "1px solid black" }),
    ...(!isActive && { border: "none" }),
    ...(isOffList && { color: "black" }),
    background: "#d3d3d3",
    minWidth: 45,
    padding: 2,
    marginRight: 8,
    fontWeight: "bold",
    hover: {
      color: "inherit",
    },
  };

  return (
    <Button
      type="button"
      className={isOffList ? "" : profilerClass}
      style={baseStyle}
      onClick={() => {
        onClick();
      }}
    >
      {label}
    </Button>
  );
};

export const ResultsTable = ({
  profile,
  value,
}: {
  profile: ProfileResult;
  value: string;
}) => {
  const { tableResult } = profile;
  const [activeTab, setActiveTab] = useState(
    Object.keys(profile.tableResult)[0]
  );

  return (
    <>
      <div style={{ marginTop: 8 }}>
        <CsvDownloadIcon value={value} />
        {Object.entries(tableResult).map(([level, levelData]) => {
          const profilerClass = `profiler${level}Word`;
          const isOffList = level === "Off-List";
          return (
            <ToggleButton
              profilerClass={profilerClass}
              isOffList={isOffList}
              isActive={activeTab === level}
              label={level}
              onClick={() => setActiveTab(level)}
            />
          );
        })}
      </div>

      <TabContent activeTab={activeTab}>
        {Object.entries(tableResult).map(([level, levelData]) => {
          return (
            <TabPane tabId={level}>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <strong>{level} Word Occurrences</strong>
              </div>

              <Table striped>
                <tbody>
                  {levelData.rows.map((r) => (
                    <tr>
                      <td style={{ width: 30 }}>{r.occurrences}</td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: r.rowHtml ?? "",
                        }}
                      ></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPane>
          );
        })}
      </TabContent>
    </>
  );
};
