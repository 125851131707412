import React, { ReactNode } from "react";
import { Container, Row } from "reactstrap";

type PropsWithChildren<P> = P & { children?: ReactNode };

export const Layout = (props: PropsWithChildren<{}>) => {
  return (
    <div>
      <Container tag="main" fluid="lg">
        <Row>
          <h1 style={{ fontSize: 18, marginTop: 5 }}>
            VocabKitchen CEFR Profiler
          </h1>
          <hr />
          {props.children}
        </Row>
      </Container>
    </div>
  );
};
